const DELETE_ITEM = "DELETE ITEM";
const INCREASE_QTY = "ADD QTY";
const DECREASE_QTY = "DIM QTY";
const EMPTY_CART = "REMOVE ALL";
const COST_TOTAL = "COST_TOTAL";
const COUNTER = "COUNTER";
const SHOW_CART = "SHOW_CART";
const ADD_CART = "ADD_CART";

export {
  SHOW_CART,
  ADD_CART,
  DELETE_ITEM,
  INCREASE_QTY,
  DECREASE_QTY,
  EMPTY_CART,
  COUNTER,
  COST_TOTAL,
};
